import { faPause, faPlay, faRedo, faStepForward, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { scriptRunnerActions } from "../../store/reducers/scriptRunnerSlice";
import { ScriptRunnerMsgTemplate } from "../../types/scriptRunnerTypes";
import { notificationActions } from "../../store/reducers/notificationsSlice";

interface IProps {
    msgTemplate: ScriptRunnerMsgTemplate;
    scriptId?: number;
    isDragging?: boolean;
    isActive?: boolean;
    setActiveItem?: boolean;
    autoStartTimer?: boolean;
    nextMsgTemplateId?: number;
}

const ShowItem: React.FC<IProps> = ({
    msgTemplate,
}) => {
    const dispatch = useTypedDispatch();
    const [showWarning, setShowWarning] = useState(false);
    const { showSnackbarWithAutoHide } = notificationActions;

    const handlePlayPause = useCallback((msgTemplate) => {
        if (msgTemplate.maxSeconds < msgTemplate.quietSeconds) {
            dispatch(
                showSnackbarWithAutoHide({
                    msgText: "Your Max Wait Time needs to be more than the Quiet Wait Time.",
                })
            );
            setShowWarning(true);
            return;
        } 
        setShowWarning(false);
        if (!msgTemplate.isRunning) {
            const intervalId = setInterval(() => dispatch(scriptRunnerActions.decrement(msgTemplate)), 1000);
            dispatch(scriptRunnerActions.setIntervalId({ id: intervalId, payload: msgTemplate }));
        }
        dispatch(scriptRunnerActions.playPauseToggle(msgTemplate));
    }, []);

    const handleMaxSecondsChange = (newMaxSeconds: any) => {
        if (newMaxSeconds <= msgTemplate.quietSeconds) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    };

    useEffect(() => {
        if (msgTemplate.startTimer) {
            handlePlayPause(msgTemplate);
        }
    }, [msgTemplate.startTimer]);

    useEffect(() => {
        if (msgTemplate.maxSeconds <= msgTemplate.quietSeconds) {
            handleMaxSecondsChange(msgTemplate.quietSeconds + 60);
        }
    }, [msgTemplate.quietSeconds]);

    const handleResetQuiet = useCallback((msgTemplate) => {
        dispatch(scriptRunnerActions.resetTimer(msgTemplate));
    }, []);

    const moveToNext = useCallback((msgTemplate) => {
        dispatch(scriptRunnerActions.moveToNext(msgTemplate));
    }, []);

    const party = useTypedSelector(
        (state) => state.party.parties.selected.party
    );

    const partyCurrent = useTypedSelector(
        (state) => state.party.parties.current.party
    );

    if (msgTemplate.isRunning && (msgTemplate.maxSeconds <= 0 || msgTemplate.quietSeconds <= 0)) {
        // dispatch(scriptRunnerActions.clearIntervalAction(msgTemplate));
        dispatch(scriptRunnerActions.moveToNext(msgTemplate));
        dispatch(scriptRunnerActions.sendAutomationMessage({ msgThreadId: partyCurrent?.id || party?.id || 0, msgTemplateId: msgTemplate.id }));
    }

    return (
        <div className="px-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className={`d-flex flex-column justify-content-center align-items-center ${msgTemplate.areButtonsDisabled ? "grayed-out" : ""}`}>
                    <div className="d-flex justify-content-between w-100">
                        <div
                            className={`bg-gray-600 px-3 py-2 rounded d-flex flex-column align-items-center cursor-move ${msgTemplate.id == 0 ? "hidden-player" : "left-title-bar"}`}
                        >
                            <p className="p4 text-center mb-0 text-white user-select-none"> 
                                {msgTemplate?.link === null ? (
                                    msgTemplate?.name
                                ) : (
                                    <a
                                        href={msgTemplate?.link || undefined}
                                        className="underline text-white"
                                        target="_blank" rel="noreferrer"
                                    >
                                        <u>{msgTemplate?.name}</u>
                                    </a>
                                )}
                            </p> 
                        </div>
                        {(<div className="d-flex justify-content-end align-items-center">
                            <div className="right-player">
                                <button onClick={() => handlePlayPause(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled} title={msgTemplate.isRunning ? "Pause" : "Play"}>
                                    <FontAwesomeIcon icon={msgTemplate.isRunning ? faPause : faPlay} />
                                </button>
                                <button onClick={() => handleResetQuiet(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled} title="Reset">
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                                <button onClick={() => moveToNext(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled} title="Skip message">
                                    <FontAwesomeIcon icon={faStepForward} />
                                </button>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="bg-gray-500 w-1px h-15px" />
                    <div className="w-140px">
                        <div className="w-140px">
                            <div className="mb-2 w-100 d-lg-flex justify-center align-items-center"> 
                                <span className="w-100 d-flex ml-6">
                                    <ProgressBar
                                        variant={showWarning ? "danger" : "success"}
                                        className="h-30px w-100"
                                        now={msgTemplate.maxProgress}
                                        label={`${msgTemplate.maxSeconds} sec`}
                                    />
                                </span>
                                <span >
                                    <FontAwesomeIcon icon={faInfoCircle} title="The max wait time is the maximum period before the next message is sent. Even if the quiet wait time resets due to an incoming response, the next message will automatically send once the max wait time is reached. You can also manage messages directly in your event manager." className="ml-2 text-primary cursor-pointer" />
                                </span> 
                            </div>
                            <div className="w-100 d-lg-flex justify-center align-items-center">
                                <span className="w-100 d-flex ml-6">
                                    <ProgressBar
                                        variant="info"
                                        className="h-30px w-100"
                                        now={msgTemplate.quietProgress}
                                        label={`${msgTemplate.quietSeconds} sec`}
                                    />
                                </span>
                                <span >
                                    <FontAwesomeIcon icon={faInfoCircle} title="The quiet wait time sets the delay, in seconds, before the next message in your flow is sent. If someone responds during this period, the counter resets to give you more time to reply manually before the next automated message appears." className="ml-2 text-primary cursor-pointer" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-500 w-1px h-15px" />
            </div>
        </div>
    );
};

export default ShowItem;
import dayjs from "dayjs";
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import GuestList from "../guest/GuestList";
import { Copy } from "../icons";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Socket } from "socket.io-client";
import { clientUrl } from "../../helpers/constants";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import CustomPagination from "../../layouts/CustomPagination";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { actions } from "../../store/reducers/partySlice";
import { scriptRunnerActions } from "../../store/reducers/scriptRunnerSlice";
import { Guest } from "../../types/Guest";
import { MsgThread, ThreadState } from "../../types/MsgThread";
import { Script } from "../../types/Script";
import { ScriptRunnerMsgTemplate } from "../../types/scriptRunnerTypes";
import CustomSelect from "../forms/fields/CustomSelect";
import ManagePartyPopover from "./ManagePartyPopover";

interface IProps {
    currentParty: MsgThread;
    guestsList: Guest[];
    guestsListPaginate: Guest[];
    guestsListLoading: boolean;
    workflowsList: Script[];
    workflowsListLoading: boolean;
    socket?: Socket;
}
const PartyDetails: React.FC<IProps> = ({
    currentParty,
    guestsList,
    guestsListPaginate,
    workflowsList,
    guestsListLoading,
    workflowsListLoading,
    socket,
}) => {
    const dispatch = useTypedDispatch();
    const { toggleNewDirectMessageModalOpen } = modalActions;
    const { showSnackbarWithAutoHide } = notificationActions;
    const gatewayId = useTypedSelector(
        (state) => state.auth.currentUser?.personalGatewayId
    );
    const {
        updateParty,
        stopParty,
        resetParty,
        startParty,
        pauseParty,
        fetchSelectedParty,
        fetchSelectedPartyGuests,
        fetchSelectedPartyGuestsPaginate,
    } = actions;
    const srciptId_Current_Party = currentParty?.scriptId
        ? currentParty?.scriptId
        : -1;
    // const [workflowSelect, setWorkflowSelect] = useState(
    //     srciptId_Current_Party
    // );
    const [filteredGuestList, setGuestList] = useState<Guest[]>([]);
    const requiresRefresh = useTypedSelector(
        (state) => state?.party?.parties?.selected?.requiresRefresh
    );

    const [apiList, setApiList] = useState<Guest[]>([]);
    const [guestPaginateList, setGuestPaginateList] = useState<Guest[]>([]);
    const { page, limit } = useTypedSelector(
        (state) => state?.party?.parties?.selected?.guests
    );

    const [currentPage, setCurrentPage] = useState(page);
    const [searchValue, setSearchValue] = useState<string>("");
    const [dataPerPage] = useState(limit);

    useEffect(() => {
        const fetchData = () => {
            dispatch(
                fetchSelectedPartyGuestsPaginate({
                    partyId: currentParty.id,
                    _page: currentPage,
                    _limit: dataPerPage,
                    search: searchValue,
                })
            );
        };
        fetchData();
    }, [currentPage, dataPerPage]);
    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);
    useEffect(() => {
        if ((guestsList as any)?.items) {
            setApiList((guestsList as any)?.items);
        }
        if ((guestsListPaginate as any)?.items) {
            setGuestPaginateList((guestsListPaginate as any)?.items);
        }
    }, [guestsList, guestsListPaginate]);
    const totalGuests = (guestsListPaginate as any)?.meta?.totalItems;
    const isLoadingState = useTypedSelector(
        (state) => state?.party?.parties?.selected?.isLoading
    );

    const handleMsgThreadStateChange = useCallback((event: string) => {
        switch (event) {
            case "msg-thread-state":
                dispatch(fetchSelectedParty({ partyId: currentParty.id }));
                break;
            case "guest-list":
                dispatch(
                    fetchSelectedPartyGuests({
                        partyId: currentParty.id,
                        _page: 1,
                        _limit: 100,
                    })
                ).then((data: any) => {
                    const guestListData = data.payload?.items || [];
                    setGuestList(guestListData);
                });
                break;
            case "reset-wait-timer":
                dispatch(scriptRunnerActions.resetActiveMessageTimer());
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (requiresRefresh) {
            dispatch(fetchSelectedParty({ partyId: currentParty.id }));
        }
    });

    // https://keyholesoftware.com/2021/04/01/react-with-socket-io-messaging-app/ starting here
    // https://www.freecodecamp.org/news/build-a-chat-app-with-react-typescript-and-socket-io-d7e1192d288/ this has ts but it is old
    useEffect(() => {
        const messageEventName = `msg-thread-${currentParty.id}`;

        // listen for incoming message
        socket?.on(messageEventName, handleMsgThreadStateChange);

        return () => {
            socket?.off(messageEventName);
        };
    }, [socket]);

    const SearchField = useTypedSearchField<Guest>({
        inputPlaceholder: "Search Guests Here...",
        btnOnClick: () => showModal(),
        itemValueSelector: (item) =>
            `${item.nameFirst} ${item.nameLast}`.toLowerCase(),
        list: guestPaginateList,
        setList: setGuestList,
        btnType: "bulkGuest",
        searchValueTransform: (value) => value.toLowerCase(),
        searching: "serverSide",
        searchOnChange: (value) => {
            dispatch(
                fetchSelectedPartyGuestsPaginate({
                    partyId: currentParty.id,
                    _page: 1,
                    _limit: dataPerPage,
                    search: value,
                })
            );
            setSearchValue(value);
            return value;
        }, // Set the value here
    });

    const partyHost = useMemo(
        () => apiList.find((guest) => guest.isHost),
        [apiList]
    );

    const showModal = useCallback(() => {
        dispatch(toggleNewDirectMessageModalOpen(true));
    }, []);

    const partyLink = `${clientUrl}/join?eventId=${
        currentParty.partyId
    }&gatewayId=${gatewayId || 0}`;

    const copyUrlToClipboard = async (link: string) => {
        try {
            await navigator.clipboard.writeText(link);
            dispatch(
                showSnackbarWithAutoHide({
                    msgText: "Copied!",
                })
            );
        } catch (err) {
            console.error("Error while writing to the clipboard!");
            dispatch(
                showSnackbarWithAutoHide({
                    msgText: "Unable to copy clipboard!",
                })
            );
        }
    };

    const handleWorkflowChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = Number(e.target.value);
        // setWorkflowSelect(newValue);
        dispatch(updateParty({ id: currentParty.id, scriptId: newValue }))
            .then(() => {
                dispatch(resetParty({ id: currentParty.id }));
            })
            .catch((error) => {
                console.error("Error occurred:", error);
            });
    };

    const handlePlayerClick = () => {
        switch (currentParty?.threadState?.name) {
            case ThreadState.SCHEDULED:
                if (currentParty?.sentTemplates?.length) {
                    dispatch(
                        scriptRunnerActions.moveToNext(
                            last(
                                currentParty?.sentTemplates as Array<ScriptRunnerMsgTemplate>
                            ) as ScriptRunnerMsgTemplate
                        )
                    );
                } else {
                    dispatch(scriptRunnerActions.startFirst());
                }
                dispatch(
                    scriptRunnerActions.sendAutomationMessage({
                        msgThreadId: currentParty.id,
                        msgTemplateIndex: 0,
                        type: "scriptIndex",
                    })
                );
                dispatch(
                    startParty({
                        id: currentParty.id,
                    })
                );
                break;
            case ThreadState.COMPLETED:
                dispatch(scriptRunnerActions.pauseAll());
                dispatch(
                    resetParty({
                        id: currentParty.id,
                    })
                );
                break;
            case ThreadState.PAUSED:
                dispatch(
                    startParty({
                        id: currentParty.id,
                    })
                )
                    .unwrap()
                    .then((data: any) => {
                        if (data?.sentTemplates?.length) {
                            dispatch(
                                scriptRunnerActions.startThis(
                                    last(
                                        data.sentTemplates as Array<ScriptRunnerMsgTemplate>
                                    ) as ScriptRunnerMsgTemplate
                                )
                            );
                        } else {
                            dispatch(scriptRunnerActions.startFirst());
                        }
                    });

                break;
            case ThreadState.IN_PROGRESS:
                dispatch(scriptRunnerActions.pauseAll());
                dispatch(
                    pauseParty({
                        id: currentParty.id,
                    })
                );
                break;
            default:
                console.error(`${currentParty.threadState} not supported`);
                break;
        }
    };

    const handleStopClick = () => {
        dispatch(
            stopParty({
                id: currentParty.id,
            })
        );
        dispatch(scriptRunnerActions.pauseAll());
    };

    return (
        <div>
            <div className="border-bottom border-secondary mb-3">
                <div className="h-20px d-flex justify-content-center align-content-center position-relative">
                    {currentParty?.threadState?.name ===
                    ThreadState.IN_PROGRESS ? (
                        <Badge
                            pill
                            variant="danger"
                            className="align-self-start text-uppercase py-1 px-4 font-weight-bolder"
                        >
                            LIVE
                        </Badge>
                    ) : currentParty?.threadState?.name ===
                      ThreadState.SCHEDULED ? (
                        <Badge
                            pill
                            variant="secondary"
                            className="align-self-start text-uppercase py-1 px-4 font-weight-bolder"
                        >
                            SCHEDULED
                        </Badge>
                    ) : currentParty?.threadState?.name ===
                      ThreadState.COMPLETED ? (
                        <Badge
                            pill
                            variant="dark"
                            className="align-self-start text-uppercase py-1 px-4 font-weight-bolder"
                        >
                            COMPLETED
                        </Badge>
                    ) : (
                        <Badge
                            pill
                            variant="secondary"
                            className="align-self-start text-uppercase py-1 px-4 font-weight-bolder"
                        >
                            {currentParty.threadState?.name}
                        </Badge>
                    )}

                    <ManagePartyPopover
                        guests={filteredGuestList}
                        party={currentParty}
                    />
                </div>
                <p className="p3 text-center mt-1 position-relative">
                    <span className="mx-1">
                        {dayjs(currentParty.timeSchedStart)
                            .tz(currentParty.timezone)
                            .local()
                            .format("MMM D, YYYY h:mm A")}
                    </span>
                    {/* &#8226;
                    <span className="mx-1">
                        {dayjs(currentParty.timeSchedStart)
                            .tz(currentParty.timezone)
                            .format("hA z")}
                    </span> */}
                </p>
            </div>
            <div className="border-bottom border-secondary mb-3">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="flex-grow-1">
                        <p className="p5 text-black-50 mb-0">Host</p>
                        {guestsListLoading ? (
                            <div className="skeleton w-50 h-30px" />
                        ) : (
                            <h3 className="text-black font-weight-bold">
                                {partyHost
                                    ? `${partyHost.nameFirst} ${partyHost.nameLast}`
                                    : "No Host"}
                            </h3>
                        )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <ButtonGroup aria-label="First group">
                            <Button
                                className="search-btn-container"
                                onClick={handlePlayerClick}
                                disabled={isLoadingState === true}
                                title={
                                    currentParty?.threadState?.name ===
                                    ThreadState.SCHEDULED
                                        ? "Start Party"
                                        : currentParty?.threadState?.name ===
                                          ThreadState.PAUSED
                                        ? "Resume Party"
                                        : currentParty?.threadState?.name ===
                                          ThreadState.IN_PROGRESS
                                        ? "Pause Party"
                                        : "Reset Party"
                                }
                            >
                                <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                                    <FontAwesomeIcon
                                        icon={
                                            currentParty?.threadState?.name ===
                                            ThreadState.SCHEDULED
                                                ? faPlay
                                                : currentParty?.threadState
                                                      ?.name ===
                                                  ThreadState.PAUSED
                                                ? faPlay
                                                : currentParty?.threadState
                                                      ?.name ===
                                                  ThreadState.IN_PROGRESS
                                                ? faPause
                                                : solid("repeat")
                                        }
                                    />
                                </span>
                            </Button>
                            <Button
                                className="search-btn-container"
                                onClick={handleStopClick}
                                disabled={
                                    currentParty?.threadState?.name ===
                                    ThreadState.COMPLETED
                                }
                                title="Stop Party"
                            >
                                <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                                    <FontAwesomeIcon icon={faStop} />
                                </span>
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className="mb-6">
                    <CustomSelect
                        title="Textflow Selected"
                        value={srciptId_Current_Party}
                        onChange={handleWorkflowChange}
                        isLoading={workflowsListLoading}
                        disabled={
                            currentParty?.threadState?.name ===
                            ThreadState.COMPLETED
                        }
                    >
                        <option disabled={true} value={-1}>
                            No selected textflow
                        </option>
                        {workflowsList?.map((workflow) => (
                            <option key={workflow?.id} value={workflow?.id}>
                                {workflow?.name}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="mb-6">
                    <p className="text-black-50 mb-1">Unique URL</p>
                    <Button
                        variant="secondary"
                        title="Click to copy"
                        onClick={() => copyUrlToClipboard(partyLink)}
                        className="form-control form-control-solid h-auto py-5 px-6 d-flex justify-content-between align-items-center"
                    >
                        <span className="text-truncate w-90">{partyLink}</span>
                        <Copy className="h-20px w-20px text-red" />
                    </Button>
                </div>
            </div>
            <div>
                {SearchField}
                <PerfectScrollbar className="pr-3 mt-2 max-h-250px">
                    <GuestList
                        guests={filteredGuestList}
                        isLoading={guestsListLoading}
                    />
                </PerfectScrollbar>
                <div className="centerPagination">
                    {totalGuests > dataPerPage && (
                        <CustomPagination
                            dataPerPage={dataPerPage}
                            totalData={totalGuests}
                            paginate={(page) => paginate(page)} // Pass the paginate function here
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PartyDetails;
